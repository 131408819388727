import {ApiClient, LocalStorageTokenStore} from '@purlife/graphql-client'
import {InMemoryCache} from '@apollo/client'

export const client = new ApiClient({
  tokenStore: new LocalStorageTokenStore('adminToken'),
  apolloCache: new InMemoryCache({resultCaching: false}),
  apiUrl: process.env.REACT_APP_API_URL,
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION,
  maxTTL: 4.5,
  decodeJWT(token) {
    return Promise.resolve(
      JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf-8'))
      )
  },
})

