import React, {Component} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import './App.scss'
import {ApolloProvider} from '@apollo/client'
import {client} from './api'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const loading = () => <div className="animated fadeIn pt-3 text-center">Lade...</div>

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client.apolloClient}>
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/anmelden" name="Anmeldeseite" render={props => <Login {...props} />}/>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />}/>
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />}/>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />}/>
            </Switch>
          </React.Suspense>
        </BrowserRouter>
        <ToastContainer/>
      </ApolloProvider>
    )
  }
}

export default App
